.grid.grid--lessons {


  .col {
    margin-bottom: 35px;
  }

  @include above($desktop-sm) {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-flow: dense;
    grid-row-gap: 35px;

    .col {
      margin: 0;

      &:nth-child(odd) {
        grid-column: 1 / span 1;
      }

      &:nth-child(even) {
        grid-column: 2 / span 1;
      }
    }

  }

  @include above($desktop) {
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
    grid-template-rows:50% 50%;
    grid-auto-flow: dense;
    grid-row-gap: 70px;

    .col {

      // Block 1
      &:nth-child(6n + 1) {
        grid-column: 1 / span 1;
      }

      // Block 2 & 3
      &:nth-child(6n + 2),
      &:nth-child(6n + 3) {
        grid-column: 2 / span 2;
        padding-left: 70px;
      }

      // Block 4 & 5
      &:nth-child(6n + 4),
      &:nth-child(6n + 5) {
        grid-column: 1 / span 2;
        padding-right: 70px;
      }

      // Block 6
      &:nth-child(6n + 6) {
        grid-column: 3 / span 1;
      }

      // Block 1 & 6
      &:nth-child(6n + 1),
      &:nth-child(6n + 6) {
        grid-row: auto / span 2;
      }
    }

  }

}
