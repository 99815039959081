.site-footer {
  padding-top: 23px;
  position: relative;
  color: $white;
  line-height: lh(24, 16);

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    width: 100%;
    mix-blend-mode: overlay;
    background-color: $red;
  }

  &__title {
    $fs: 16;
    font-family: $title-font-family;
    font-size: rem($fs);
    font-weight: 700;
    margin: 0 0 15px 0;
  }

  &__top {
    background-color: $blue;
    padding-top: 40px;
    padding-bottom: 20px;
  }

  &__bottom {
    border-top: 1px solid #424866;
    background-color: $darkblue;

    .site-footer__inner {
      height: 80px;
      display: flex;
      align-items: center;
    }

  }

  a {
    color: $white;
  }
}


// Grid
.site-footer {

  &__inner {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &__menu {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }

  &__social {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    text-align: right;
  }

  &__address {
    display: none;
  }

  &__newsletter {
    grid-column: 1 / span 2;
    border-top: 1px solid rgba(white, .2);
    margin: 20px -20px 0;
    padding: 20px;
  }
}


@include above($tablet) {
  .site-footer {
    &__inner {
      grid-template-columns: 20% 20% 20% 20% 20%;
      grid-template-rows: 50% 50%;
    }

    &__menu {
      grid-column: 1 / span 1;
      grid-row: 1 / span 2;
    }

    &__social {
      grid-column: 5 / span 1;
      grid-row: 2 / span 1;
      padding-top: 20px;
    }

    &__address {
      display: block;
      grid-column: 2 / span 2;
      grid-row: 1 / span 2;
    }

    &__newsletter {
      grid-column: 4 / span 2;
      grid-row: 1 / span 1;
      border: none;
      margin: 0;
      padding: 0;
    }
  }
}


@include above($desktop) {
  .site-footer {
    &__top {
      background-color: $blue;
      padding-top: 70px;
      padding-bottom: 35px;
    }

    &__inner {
      display: flex;
      flex-wrap: wrap;
    }

    &__menu {
      width: 234px;
    }

    &__social {
      text-align: left;
      padding: 0;
    }

    &__address {
      width: 360px;
    }

    &__newsletter {
      flex-grow: 1;
      padding-right: 40px;
      max-width: 650px;

      &__inner {
        max-width: 330px;
      }
    }

  }

}

.site-footer__bottom {
  .site-footer__inner {
    display: flex;
    align-items: center;
  }
  .site-footer__title {
    margin: 0;
  }

  .site-footer__sponsors{
    margin-left: 20px;
  }
}
