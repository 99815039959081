.form--footer-newsletter {
  padding: 0;
  width: 100%;

  @include above($desktop) {
    max-width: 233px;
  }

  .field-wrapper {
    position: relative;
    margin: 0;
    display: flex;
  }

  .field {
    width: calc(100% - 40px);


    input {
      $fs: 13;
      background-color: $darkblue;
      color: $white;
      font-size: rem($fs);
      letter-spacing: ls(1.38, $fs);
      font-family: $title-font-family;
      padding: 0 em(10, $fs);

      @include placeholder() {
        color: $white;
      }
    }
  }

  .field::after {
    display: none;
  }

  .btn--small {
    &, & .icn {
      width: 40px;
      height: 40px;
    }

    @include btn-hover() {
      background-color: $darkblue;
      .icn { width: 40px; }
    }
  }

}

.form--footer-newsletter--wrapper {
  position: relative;
  min-height: 40px;
  display: block;
}

.form--footer-thankyou--container,
.form--footer-newsletter--container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity .3s ease-in-out;

  h1 {
    margin: 0;
    font-size: 26px;
  }

  &.hide {
    opacity: 0;
  }
}
